var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"pl-8 pr-8 ml-3 mr-3 mb-xl-candidate-list"},[_c('v-row',{attrs:{"justify":"center"}},[_c('v-col',{staticClass:"pl-0 pr-0 text-left",attrs:{"cols":"12"}},[_c('h1',{staticClass:"candidate-list__title"},[_vm._v("Lista de candidatos")])])],1),_c('v-row',[_c('v-col',{attrs:{"cols":"4"}},[_c('v-btn',{staticClass:"mt-3",attrs:{"color":"#466BE3","rounded":"","dark":"","block":""},on:{"click":_vm.download}},[_c('strong',[_vm._v("Descargar cantidatos ")])])],1),_c('v-col',{attrs:{"cols":"1"}},[_c('v-btn',{staticClass:"mt-3",attrs:{"color":"#466BE3","rounded":"","dark":"","block":""},on:{"click":_vm.goBack}},[_c('strong',[_vm._v("Volver ")])])],1),_c('v-col',{staticClass:"pl-0 pr-0",attrs:{"cols":"12"}},[_c('v-data-table',{staticClass:"elevation-1 candidate-list__table",staticStyle:{"border-radius":"15px","height":"100%","border":"solid 1.2px #e0e0e1"},attrs:{"loading":_vm.loading.table,"items-per-page":15,"headers":_vm.headers,"items":_vm.listCandidate,"footer-props":{ itemsPerPageOptions: [5, 10, 15] }},scopedSlots:_vm._u([{key:"no-results",fn:function(){return [_c('v-alert',{staticClass:"mt-3 forms__alert-completed",attrs:{"text":"","dense":"","type":"info"}},[_c('b',[_vm._v("No se encontraron resultados.")])])]},proxy:true},{key:"no-data",fn:function(){return [_c('v-alert',{staticClass:"mt-3 forms__alert-completed",attrs:{"text":"","dense":"","type":"info"}},[_c('b',[_vm._v("No se encontró información.")])])]},proxy:true},{key:"progress",fn:function(){return [_c('v-progress-linear',{attrs:{"color":"#214484","height":5,"indeterminate":"","stream":""}})]},proxy:true},{key:"item.title",fn:function(ref){
var item = ref.item;
return [_c('div',[_c('p',{staticClass:"mb-0 mt-2 text-1-1-lines"},[_vm._v(" "+_vm._s(item.title)+" ")])])]}},{key:"item.numero_de_vacantes",fn:function(ref){
var item = ref.item;
return [_c('div',[_c('p',{staticClass:"mb-0 mt-2 text-1-1-lines"},[_vm._v(" "+_vm._s(item.numero_de_vacantes)+" ")])])]}},{key:"item.origen",fn:function(ref){
var item = ref.item;
return [_c('div',[_c('p',{staticClass:"mb-0 mt-2 text-1-1-lines"},[_vm._v(" "+_vm._s(item.origen)+" ")])])]}},{key:"item.status_name",fn:function(ref){
var item = ref.item;
return [(item.status_name)?_c('v-chip',{attrs:{"color":_vm.getColorStatus(item.status_name),"dark":"","small":""}},[_vm._v(" "+_vm._s(item.status_name)+" ")]):_vm._e()]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('v-btn',{attrs:{"fab":"","icon":"","small":""},on:{"click":function($event){return _vm.goDeteils(item)}}},[_c('v-icon',{style:({
                    color: hover ? '#324CA1' : '#466BE3',
                  }),attrs:{"small":""}},[_vm._v(" mdi-magnify ")])],1)]}}],null,true)})]}}])})],1)],1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }